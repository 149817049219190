@import "../assets/global.scss";

.modal {
  position: fixed;
  display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow-x: hidden;
  // background-color: rgba(31, 32, 41, .95);
  background-color: $secondaryLightBlur;
  backdrop-filter: blur(5px);
  pointer-events: auto;
  opacity: 1;
  transition: opacity 200ms 200ms ease, transform 250ms 200ms ease;

//   transition: opacity 250ms 700ms ease;
}

.visible {
  
//   transition: all 300ms ease-in-out;
}

.modal__wrap {
  // overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  min-height: 100px;
  min-width: 400px;
  max-height: 95%;
  max-width: 95%;
  margin: 0 auto;
  padding: .5em;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // border-radius: 10px;
  // padding: 20px 20px;
  background-color: none;
  align-self: center;
  // box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 200ms 200ms ease, transform 250ms 200ms ease;
  transform: scale(0);
  overflow: auto;
  position: relative;
  // border-radius: 1em;
  // box-shadow: $shadow;
}

.visible .modal__wrap {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms 200ms ease, transform 250ms 200ms ease;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonclose {
  position: fixed;
  bottom: 1em;
  right: 1em;
}
.buttonClose:hover {
  color: $primary;
  cursor: pointer;
}

  
