body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Add this CSS to your styles.css or a separate CSS file */

body {
  background-color: #1c2733; /* Dark background color */
  color: #fff; /* Text color */
  font-family: Arial, sans-serif; /* Font style */
}

/* Styling for buttons */
button {
  background-color: #007acc; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #222; /* Header background color */
}

/* Footer styles */
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #222; /* Footer background color */
}

/* Common styles for components */
.component {
  margin: 20px;
  padding: 20px;
  background-color: #444; /* Component background color */
  border: 1px solid #666; /* Component border color */
  border-radius: 5px;
}

/* Style for lists */
ul {
  list-style-type: none;
  padding: 0;
}

/* Style for list items */
li {
  margin: 10px 0;
}

/* Style for select dropdowns */
select {
  padding: 10px;
  border: 1px solid #666;
  border-radius: 5px;
  background-color: #444; /* Dropdown background color */
  color: #fff; /* Dropdown text color */
}

/* Style for images */
img {
  max-width: 100%;
  height: auto;
}

/* Style for profile page buttons */
.accept-button, .reject-button {
  background-color: #007acc; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

/* Style for individual stats items on the landing page */
.stat-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

/* Style for background image */
.stat-background {
  background: url('background.jpg') no-repeat center center;
  background-size: cover;
  padding: 20px;
  border-radius: 5px;
}

