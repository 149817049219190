@import "../assets/global.scss";

.page {

    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $secondary;
    gap: .5em;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.flexbox {
    display: flex;
    justify-content: end;
    padding-right: 20px;
    width: 300px;
    // padding-top: 5px;

}

.link {
    @include link

}

.logo {
    font-family: 'Times New Roman', Times, serif;
    img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
}