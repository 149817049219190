@import "../assets/global.scss";

.playerGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 400px;
  margin: 0 auto;
}

.playerSquare {
  width: 160px;
  min-height: 150px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptySquare {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.filledSquare {
  background-color: #ffffff;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 10px;
  color: #000;
}

.filledSquare p {
  margin: 5px 0;
}

/* Style for the player dialog */
.playerDialog {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  background-color: $secondary;
  padding: 20px;
  //   border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  box-shadow: $shadow;

  .header {
    color: $secondaryLight;
    font-weight: 800;
    font-size: 1.3em;
  }
}

.playerDialog .save {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

/* Style for the Save button */
.playerDialog button:hover {
  background-color: #0056b3;
}

.deleteButton {
  grid-column: 2;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  position: relative;
  display: grid;
}

.select {
  grid-column: 1;
}

.deleteIcon {
  grid-column: 2;
  color: red;
  font-size: 24px;
  display: grid;
  width: 24px;
  height: 24px;
  border: 2px solid red;
  border-radius: 50%;
  text-align: center;
  line-height: 20px; /* Adjust this value to center the "x" vertically */
}

.companionContainer {
  display: grid;
  grid-template-columns: 90% 10%; /* 90% for the Select, 10% for the delete button */
  grid-gap: 8px; /* Adjust the gap between the Select and the delete button as needed */
  align-items: center;
}

.fieldContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  label {
    width: 100px;
    text-align: right;
    font-family: "Times New Roman", Times, serif;
    font-size: 1em;
  }

  select {
    height: 30px;
    padding: 0px;
    width: 65px;
    padding: 0px 10px;
  }
}

.myselect {
  background-color: $secondary;
}
