@import "../assets/global.scss";

.gameContainer {
  background-color: $secondaryMed;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  box-shadow: $shadow;
  width: fit-content;

  .top {
    display: flex;
    flex-wrap: wrap;
    span {
      @include normal-text;
      padding: 0px 10px;
    }
  }
}

.players {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5em;
}

.addPlayer {
  border: 1px solid $secondaryLight;
  border-radius: 10px;
  width: 160px;
  height: 150px;
  font-weight: 600;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .3s all;

  &:hover {
    background-color: $primary;
    border: 1px solid $primary;
    box-shadow: $shadow;
    color: $secondary;
  }
}

.player {
    transition: .3s all;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        box-shadow: $shadow;
        background-color: $primaryMed;
    }
}
