@import "../assets/global.scss";

.logo {
  font-family: "Times New Roman", Times, serif;
  display: flex;
  gap: 1em;
  align-items: center;

  .userInfo {
    display: flex;
    flex-direction: column;
    gap: .1em;

    .username {
        font-size: 1.3em;
        font-weight: 700;
    }

    .email {
        font-size: .8em;
        color: $secondaryMed;
    }

    .games {
        display: flex;
        gap: .5em;
        .stat {
            font-size: .8em;
            span {
                @include link
            }
        }
      
    }
  }

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: $secondary;
  border-bottom: 1px solid $secondaryMed;
  align-items: center;
}
