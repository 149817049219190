@import "../assets/global.scss";

.playerContainer {
//   border: 1px solid $secondary;
  border-radius: 10px;
  width: 160px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  img {
    height: 50px;
    width: 100%;
    object-fit: cover;
    // border-radius: 9.5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    object-position: 100% 0;
  }
}

// .gold {
//   border: 1px solid $gold;
// }

// .silver {
//   border: 1px solid $silver;
// }

// .bronze {
//   border: 1px solid $bronze;
// }

.top {
    position: relative;
    .place {
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 12px;
        width: 12px;
        background-color: $black;
        font-size: .6em;
        box-shadow: $shadow;
        font-weight: 700;
      }
      
      .goldPlace {
        background-color:  $gold;
      }
      
      .silverPlace {
        background-color: $silver;
      }
      
      .bronzePlace {
        background-color: $bronze;
      }

      .ratio {
        position: absolute;
        left: 5px;
        top: 5px;
        font-size: .5em;
        font-weight: 700;
      }
}



.username {
  @include normal-text;
  font-size: 1.2em;
  font-weight: 600;
  display: flex;
  gap: 0.2em;
  align-items: center;

  img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    object-fit: cover;
    object-position: none;
    box-shadow: $shadow;
  }

  span {
    font-size: 0.5em;
  }
}

.stat {
  @include normal-text;
  font-size: 0.9em;

  span {
    @include link;
  }
}


.stats {
  padding: 0px 5px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.top2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 0.5em;
  span {
    @include normal-text;
    font-size: 0.6em;
    padding: 0px 10px;
  }
}

.bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      @include link;
    }
    padding: 5px;
    align-items: end;
  }

.mana {
    height: 10px;
    width: 10px;
    background-color: $black;
    border-radius: 50%;
    box-shadow: $shadow;
}

.red {
    background-color: $red;
}
.green {
    background-color: $green;
}
.blue {
    background-color: $blue;
}
.black {
    background-color: $black;
}
.white {
    background-color: $white;
}


.colors {
    display: flex;
    gap: .1em;
}