$primary: #20c997;
$primaryMed: #92e2c8;
$primaryLight: #e5f9f3;

$secondary: #1c2733;
$secondaryMed: #555f6a;
$secondaryLight: #dee2e6;
$secondaryLightBlur: #dee2e662;

$gold: #ffd540;
$silver: #c2d1e0;
$bronze: #a07e4a;

$black: #000000;
$white: #ffffff;
$red: #FF727A;
$blue: #91CAFF;
$green: #94F19A;

$shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

@mixin normal-text($color: $secondaryLight) {
  font-family: "Times New Roman", Times, serif;
  font-size: 1em;
  color: $color;
}

@mixin link($color: $primary) {
  font-family: "Times New Roman", Times, serif;
  font-size: .9em;
  color: $color;
  transition: .3s all;
  cursor: pointer;


  &:hover {
    text-decoration: underline;
  }
}