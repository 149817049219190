@import "../assets/global.scss";


.textBox {
    width: 300px;
    height: 30px;
    font-size: .9em;
    outline: none;
    border-radius: 10px;
    border: 2px solid $secondaryMed;
    transition: .3s all;
    background-color: transparent;
    color: $white;
    padding-left: 10px;

    
    &:focus {
        border: 2px solid $primary
    }
}