@import "../assets/global.scss";

.page {
  background-color: $secondary;
  height: 100%;
  padding-bottom: 300px;
}

.butContainer {
  position: sticky;
  bottom: 2em;
  right: 1em;
  z-index: 10;
}

.games {
  padding: 1em 0.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1em;
  padding: 0.5em;
}

.navItem {
  padding: 0.5em;
  border-radius: 10px;
  color: $secondaryLight;
  cursor: pointer;
  &:hover {
    background-color: $secondaryMed;
  }
}

.active {
  // color: $primary;
  background-color: $secondaryMed;
  box-shadow: $shadow;

  // text-decoration: underline;
}

.mana {
  height: 15px;
  width: 15px;
  background-color: $black;
  border-radius: 50%;
  box-shadow: $shadow;
}

.red {
  background-color: $red;
}
.green {
  background-color: $green;
}
.blue {
  background-color: $blue;
}
.black {
  background-color: $black;
}
.white {
  background-color: $white;
}

.colors {
  display: flex;
  gap: 3px;
}

.deckPage {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0em 1em;
  align-items: center;
}
.deck {
  // border: 2px solid $secondaryMed;
  position: relative;
  background-color: $secondaryMed;
  border-radius: 10px;
  padding: 5px 5px;
  // padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
  transition: 0.3s all;
  &:hover {
    background-color: $primary;
  }
  width: 100%;
  max-width: 500px;
  cursor: pointer;

  img {
    height: 100px;
    width: 100%;
    max-width: 250px;
    object-fit: cover;
    border-radius: 9.5px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    object-position: 100% 0;
  }
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 5px;
  font-size: 0.8em;
  // padding: 10px 0px;
}

.ratio {
  // position: absolute;
  font-size: 1.3em;
  font-weight: 700;
  // border: 2px solid black;
  width: 160px;
  text-align: right;
  
}
