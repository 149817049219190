@import "../assets/global.scss";

.thebutton {
    background-color: $primary;
    // font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    border-radius: 10px;
    box-shadow: $shadow;
    padding: 10px 20px;
    height: 40px;
    width: fit-content;

    // &:hover {

    // }

}